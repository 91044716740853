.container {
  margin: auto;
  width: 300px;

  .title {
    font-size: 25px;
    font-weight: bold;
    padding: 10px;
  }

  .item {
    padding: 5px;
    display: flex;

    label {
      width: 90px;
    }

    .locLabel {
      position: absolute;
      right: 230px;
    }

    input {
      padding: 6px;
    }
  }

  .buttons {
    margin-top: 30px;

    button {
      font-size: large;
      margin: 10px;
    }
  }
}