// chat-window.module.scss
.chatWindow {
  position: relative;
  margin: 10px;
  padding: 10px;

  .appBar {
    border-radius: 12px;
    padding-right: 0px;
    .rightToolbar {
      margin-left: auto;
    }
  }

  .messagesContainer {
    height: 300px;
    width: 330px;
    overflow-y: auto;
    padding: 10px;
    background-color: #ffffff;
    background-image: radial-gradient(
      rgba(12, 12, 12, 0.171) 2px,
      transparent 0
    );
    background-size: 30px 30px;
    background-position: -5px -5px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    scrollbar-width: thin; /* Para navegadores que admiten el nuevo estándar */
    scrollbar-color: transparent transparent; /* Para navegadores que admiten el nuevo estándar */

    &::-webkit-scrollbar {
      width: 6px; /* Ajusta el ancho según sea necesario */
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  .emojiPickerContainer {
    position: absolute;
    bottom: 70px;
    right: 10px;
    z-index: 100;
  }

  .inputContainer {
    padding: 10px;
  }

  .messageSent {
    text-align: left;
    background-color: #52ff01;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    align-self: flex-start;
    word-wrap: break-word;
    max-height: 100px;
    justify-content: flex-end;
  }

  .messageReceived {
    text-align: right;
    background-color: #ffae01;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    align-self: flex-end;
    word-wrap: break-word;
    max-height: 100px;
    justify-content: flex-end;
  }

  .messageContent {
    margin-bottom: 5px;
  }

  .messageTimestamp {
    font-size: 0.8rem;
    color: black;
  }

  .userOnlineIndicator {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    border: black;
    border-width: 3px;
    border-style: inset;
    background-color: rgb(0, 255, 0);
  }

  .userOfflineIndicator {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    border-color: black;
    border-width: 3px;
    border-style: inset;
    background-color: rgb(102, 102, 102);
  }

  .newMessage {
    background-color: purple;
  }
}
