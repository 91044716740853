.container {
  background-image: url(images/love-background.jpg);
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  font-family: SFProDisplay-Regular, Helvetica, Arial, sans-serif;
  font-size: 28px;
  padding: 15px;
  margin: auto;
  width: 100%;
  height: 100%;

  .buttons {
    margin: auto;
    margin-top: 20px;
    width: 200px;
  }
}