.container {
}

.chatContainerWrapper {
  display: flex;
  position: fixed;
  bottom: 0px;
  right: 0px;
  flex-direction: row-reverse;
  gap: 10px;
  z-index: 1000;
  align-items: flex-end;
}

.menuButton {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 23px;
  cursor: pointer;
  z-index: 1001;
}

.slideMenuClosed {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100vh;
  background-color: #fff0f5;
  transition: left 0.3s;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    font-size: 23px;
    font-family: cursive;
    margin: 10px 0;
    text-decoration: none;
    color: #ff69b4;
  }
}

.slideMenuOpen {
  @extend .slideMenuClosed;
  left: 0;
}

.filtersContainer {
  display: flex;
  justify-content: space-around;
}

.filterItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.love {
  position: relative;
  width: 30px;
  height: 30px;
}

.love-heart:before,
#switch {
  display: none;
}

.love-heart,
.love-heart::after {
  border-color: hsl(231deg 28% 86%);
  border: 1px solid;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  width: 10px;
  height: 8px;
  border-bottom: 0;
}

.round {
  position: absolute;
  z-index: 1;
  width: 8px;
  height: 8px;
  background: hsl(0deg 0% 100%);
  box-shadow: rgb(0 0 0 / 24%) 0px 0px 4px 0px;
  border-radius: 100%;
  left: 0px;
  bottom: -1px;
  transition: all 0.5s ease;
  animation: check-animation2 0.5s forwards;
}

input:checked + label .round {
  transform: translate(0px, 0px);
  animation: check-animation 0.5s forwards;
  background-color: hsl(0deg 0% 100%);
}

@keyframes check-animation {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, 7px);
  }

  100% {
    transform: translate(7px, 7px);
  }
}

@keyframes check-animation2 {
  0% {
    transform: translate(7px, 7px);
  }

  50% {
    transform: translate(0px, 7px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.newMessage {
  background-color: rgba(
    255,
    255,
    0,
    0.3
  ); /* Fondo semi transparente amarillo */
  border-radius: 8px;
  padding: 8px;
}

.switch {
  --input-focus: #f519c9;
  --font-color: #323232;
  --font-color-sub: #666;
  --bg-color: #fff;
  --bg-color-alt: #666;
  --main-color: #323232;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 50px;
  height: 20px;
}

.toggle {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  box-sizing: border-box;
  border-radius: 5px;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-colorcolor);
  transition: 0.3s;
}

.slider:before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  left: -2px;
  bottom: 2px;
  background-color: var(--bg-color);
  box-shadow: 0 3px 0 var(--main-color);
  transition: 0.3s;
}

.toggle:checked + .slider {
  background-color: var(--input-focus);
}

.toggle:checked + .slider:before {
  transform: translateX(30px);
}

// loading...

.center {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin-top: 25%;
}
.loader {
  display: flex;
  position: relative;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
  gap: 1rem;
  height: 55px;
  width: 200px;
  overflow: hidden;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 200px;
  position: relative;
  align-items: center;
}

.carousel {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  position: absolute;
  width: 100%;
  transform-origin: center;
  animation-delay: 2s;
}

.loader .container:nth-child(3) {
  justify-content: flex-start;
  justify-items: flex-start;
  animation: scroll-up 4s infinite ease-in-out;
  animation-delay: 3s;
}

.loader .container:nth-child(2) {
  justify-content: flex-end;
  justify-items: flex-end;
  animation: scroll-down 4s infinite ease-in-out;
  animation-delay: 3s;
}

.loader .container:nth-child(1) {
  justify-content: flex-end;
  justify-items: flex-end;
  animation: scroll-down 3s infinite ease-in-out;
  animation-delay: 3s;
}

.love {
  background: black;
  display: flex;
  width: 30px;
  height: 30px;
  position: relative;
  align-items: center;
  justify-content: center;
  left: 8px;
  margin: 0.8rem 4px;
  transform: rotate(45deg);
  animation-delay: 2s;
}

.love::before,
.love::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: darkred;
}

.love::before {
  left: -16px;
}

.love::after {
  top: -16px;
}

.death {
  display: flex;
  width: 100%;
  height: 55px;
  position: relative;
  align-items: center;
  justify-content: center;
  animation: rotation 3s infinite ease-in-out;
  animation-delay: 1s;
}

.death:after {
  content: "";
  height: 63px;
  position: absolute;
  border-left: 12px solid black;
  transform: rotate(45deg);
  border-radius: 8px;
  top: -4px;
}

.death:before {
  content: "";
  height: 60px;
  position: absolute;
  border-left: 12px solid darkred;
  transform: rotate(-45deg);
}

.loader:hover {
  animation: none;
}

.robots {
  display: flex;
  width: 100%;
  height: 55px;
  justify-content: space-between;
  background-color: black;
  border-radius: 0 8px 8px;
  padding: 8px;
  animation-delay: 5s;
}

.robots::after {
  content: "";
  width: 12px;
  height: 12px;
  top: 0;
  left: 0;
  background-color: darkred;
  border-radius: 50%;
  animation-delay: 2s;
  animation: blink 0.5s 2 forwards;
}

.robots::before {
  content: "";
  width: 12px;
  height: 12px;
  top: 0;
  left: 0;
  background-color: darkred;
  border-radius: 50%;
  animation-delay: 2s;
  animation: blink 0.5s 2 forwards;
}

@keyframes scroll-up {
  0% {
    transform: translateY(0);
    filter: blur(0);
  }

  30% {
    transform: translateY(-150%);
    filter: blur(10px);
  }

  60% {
    transform: translateY(0);
    filter: blur(0px);
  }
}

@keyframes scroll-down {
  0% {
    transform: translateY(0);
    filter: blur(0);
  }

  30% {
    transform: translateY(150%);
    filter: blur(10px);
  }

  60% {
    transform: translateY(0);
    filter: blur(0px);
  }
}

@keyframes rotation {
  20%,
  100% {
    transform: rotate(180deg);
  }
}

@keyframes blink {
  0% {
    height: 0;
  }

  20% {
    height: 12px;
  }

  100% {
    height: 12px;
  }
}
