.questionAnswer {
  display: block;

  .question {
    display: table-cell;
    font-weight: bold;
    display: table-cell;
    padding-right: 10px;
  }
}

.answerBlock {
  display: block;
  padding: 10px;

  .answerItem {
    display: block;
  }
}
